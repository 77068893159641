<template>
  <div class="common-share">
    <div
      class="common-share-container"
      @click="shareClick"
      :class="viewState.dialogVisible && 'dialog-visible'"
    >
      <div class="common-share-text">Share</div>
      <icon name="share-icon" class="common-share-icon"></icon>
      <icon name="share-icon-active" class="common-share-icon-active"></icon>
    </div>

    <el-dialog
      v-model="viewState.dialogVisible"
      fullscreen
      lock-scroll
      center
      destroy-on-close
      custom-class="common-share-dialog"
    >
      <div class="common-share-dialog-container">
        <div class="common-share-dialog-container-header">
          <div class="header-titles">
            <div class="header-title">Share</div>
            <div class="header-subtitles">共有</div>
          </div>
          <div class="header-close-btn" @click="closeClick">
            <icon
              name="common-share-close-btn"
              class="common-share-close-btn"
            ></icon>
            <icon
              name="sp-common-share-close-btn"
              class="sp-common-share-close-btn"
            ></icon>
          </div>
        </div>
        <div class="common-share-dialog-container-centenr">
          <div class="share-url">
            {{ viewState.shareUrl }}
          </div>
          <div class="copy-link-btn" @click="copyLink">Copy Link</div>
        </div>
        <p class="success-text" v-if="viewState.successText">
          URLをコピーしました。
        </p>
        <!-- div class="common-share-dialog-container-bottom">
          <div class="share-icons">
            <icon name="facebook" class="facebook-icon" />
            <icon name="facebook-active" class="facebook-icon-active" />
          </div>
          <div class="share-icons">
            <icon name="google" class="google-icon" />
            <icon name="google-active" class="google-icon-active" />
          </div>
          <div class="share-icons">
            <icon name="twitter" class="twitter-icon" />
            <icon name="twitter-active" class="twitter-icon-active" />
          </div>
          <div class="share-icons">
            <icon name="mail" class="mail-icon" />
            <icon name="mail-active" class="mail-icon-active" />
          </div>
        </div -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ElDialog } from "element-plus";
import useClipboard from "vue-clipboard3";
import { defineComponent, onMounted, reactive, watch } from "vue";

export default defineComponent({
  name: "lecture",
  components: {
    ElDialog,
  },
  setup() {
    const viewState = reactive({
      dialogVisible: false,
      shareUrl: "",
      successText: false,
    });

    // method
    const shareClick = () => {
      viewState.dialogVisible = true;
    };
    const closeClick = () => {
      viewState.dialogVisible = false;
      viewState.successText = false;
    };
    const copyLink = () => {
      const { toClipboard } = useClipboard();
      toClipboard(viewState.shareUrl);
      viewState.successText = true;
    };

    onMounted(() => {
      viewState.shareUrl = location.href;
    });

    return {
      viewState,

      // method
      shareClick,
      closeClick,
      copyLink,
    };
  },
});
</script>

<style lang="scss" >
.common-share {
  .common-share-container {
    cursor: pointer;
    width: 83px;
    height: 20px;
    display: flex;
    justify-content: flex-end;

    .common-share-text {
      font-family: "Roboto Condensed", sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.08em;
      color: $black;
      word-break: normal;
      @include mq(sm) {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: right;
        letter-spacing: 0.08em;
        color: $black;
      }
    }
    .common-share-icon {
      margin-left: 16px;
    }
    .common-share-icon-active {
      display: none;
      margin-left: 16px;
    }
    &:active,
    &:hover,
    &.dialog-visible {
      .common-share-text {
        color: $primary;
      }
      .common-share-icon {
        display: none;
        margin-left: 16px;
      }
      .common-share-icon-active {
        display: flex;
        margin-left: 16px;
      }
    }
  }

  .el-overlay {
    backdrop-filter: blur(5px);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      rgba(54, 131, 245, 0.1);
  }
  .common-share-dialog {
    display: flex;
    align-items: center;
    .el-dialog__body {
      padding: 0;
      margin: 0 auto;
      width: 850px;
      height: 330px;
      background: $white;
      box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
      @include mq(sm) {
        width: 315px;
        height: 395px;
      }
      .common-share-dialog-container {
        display: flex;
        flex-direction: column;
        .common-share-dialog-container-header {
          height: 80px;
          display: flex;
          justify-content: space-between;

          border-bottom: 0.5px solid $black-50;
          @include mq(sm) {
            height: 60px;
            border-bottom: 1px solid $black;
          }
          .header-titles {
            display: flex;
            align-items: center;
            .header-title {
              margin-left: 50px;
              font-family: "Roboto Condensed", sans-serif;
              font-weight: 700;
              font-size: 40px;
              line-height: 60px;
              display: flex;
              align-items: center;
              letter-spacing: 0.05em;
              color: $black;
              @include mq(sm) {
                margin-left: 20px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 700;
                font-size: 30px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: $black;
              }
            }
            .header-subtitles {
              margin-left: 20px;
              font-weight: 500;
              font-size: 20px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: 0.08em;
              color: $black;
              opacity: 0.5;
              margin-top: 6px;
              @include mq(sm) {
                margin-left: 10px;
                font-family: "Roboto Condensed", sans-serif;
                font-weight: 300;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: $black;
                opacity: 0.5;
                margin-top: 3px;
              }
            }
          }
          .header-close-btn {
            width: 80px;
            height: 80px;
            cursor: pointer;
            border-left: 0.5px solid $black-50;
            .common-share-close-btn {
              width: 80px;
              height: 80px;
            }
            .sp-common-share-close-btn {
              display: none;
            }
            @include mq(sm) {
              width: 60px;
              height: 60px;
              border-left: none;
              background: $primary;
              .common-share-close-btn {
                display: none;
              }
              .sp-common-share-close-btn {
                display: block;
                width: 60px;
                height: 60px;
              }
            }
          }
        }
        .common-share-dialog-container-centenr {
          width: 750px;
          height: 61px;
          margin-left: 50px;
          margin-top: 50px;
          border: 0.5px solid $black-50;
          display: flex;
          justify-content: space-between;
          @include mq(sm) {
            margin-left: 20px;
            margin-top: 20px;
            width: 275px;
            height: 215px;
            flex-direction: column;
            position: relative;
          }
          .share-url {
            overflow-x: auto;
            margin-left: 30px;
            font-weight: 500;
            font-size: 20px;
            line-height: 70px;
            display: flex;
            align-items: center;
            letter-spacing: 0.08em;
            color: $dark-grey;
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            max-width: 600px;
            @include mq(sm) {
              margin-left: 20px;
              margin-top: 20px;
              margin-right: 20px;
              height: 155px;
              white-space: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 23px;
              letter-spacing: 0.08em;
              align-items: flex-start;
              color: $dark-grey;
              &::after {
                content: unset;
              }
            }
          }
          .copy-link-btn {
            cursor: pointer;
            width: 150px;
            height: 60px;
            background: $black;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
            font-weight: 700;
            font-size: 18px;
            line-height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.08em;
            color: $white;
            @media (any-hover: hover) {
              &:hover,
              &:active {
                background: $primary;
              }
            }
            @include mq(sm) {
              width: 275px;
              height: 60px;
            }
          }
        }
        .success-text {
          margin-left: 50px;
          margin-top: 20px;
          @include mq(sm) {
            margin-left: 20px;
          }
        }
        .common-share-dialog-container-bottom {
          display: flex;
          height: 60px;
          margin-top: 30px;
          justify-content: center;
          @include mq(sm) {
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 20px;
          }
          .share-icons {
            cursor: pointer;
            width: 60px;
            height: 60px;
            border: 0.5px solid $black-50;
            margin-right: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mq(sm) {
              margin-right: 11px;
              &:last-child {
                margin-right: 0px;
              }
            }
            .facebook-icon {
              width: 10.71px;
              height: 20px;
            }
            .facebook-icon-active {
              display: none;
              width: 10.71px;
              height: 20px;
            }
            .google-icon {
              width: 20px;
              height: 20px;
            }
            .google-icon-active {
              display: none;
              width: 20px;
              height: 20px;
            }
            .twitter-icon {
              width: 25px;
              height: 20px;
            }
            .twitter-icon-active {
              display: none;
              width: 25px;
              height: 20px;
            }
            .mail-icon {
              width: 25px;
              height: 20px;
            }
            .mail-icon-active {
              display: none;
              width: 25px;
              height: 20px;
            }
            @media (any-hover: hover) {
              &:hover,
              &:active {
                background: $primary;
                border: 0.5px solid $primary;
                .facebook-icon {
                  display: none;
                }
                .facebook-icon-active {
                  display: block;
                }
                .google-icon {
                  display: none;
                }
                .google-icon-active {
                  display: block;
                }
                .twitter-icon {
                  display: none;
                }
                .twitter-icon-active {
                  display: block;
                }
                .mail-icon {
                  display: none;
                }
                .mail-icon-active {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>